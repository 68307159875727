import {CircularProgress} from "@mui/material";
import moment from "moment";
import {useEffect, useState} from "react";
import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Navigate, useLocation, useNavigate, Link} from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";
import LinkButton from "components/LinkButton";
import LoadingScreen from "components/LoadingScreen";
import PaymentInfo from "components/PaymentInfo";
import QuoteStatusInfo from "components/QuoteStatusInfo";
import {useAuth} from "providers/Auth";
import {useQuoteDetails} from "providers/QuoteDetails";
import {api} from "services";
import {PaymentMethod, RoleDescription} from "types/enums";
import {Quote} from "types/quotes";
import {formatPrice} from "utils/formats";

interface ILocationState {
  date: Date;
  totalValue: number;
  companyId: number;
  professionalId: number;
}

const ScheduleDetails = () => {
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const {userRole} = useAuth();
  const {pendingItems} = useQuoteDetails();

  if (!location.state) {
    return <Navigate to="/" />;
  }

  const eventDate = moment((location.state as ILocationState).date).add(1, "d");

  useEffect(() => {
    async function loadQuotes() {
      try {
        const {data} = await api.get("/Payment/QuoteForecastingDetails", {
          params: {
            date: eventDate.format("YYYY-MM-DD"),
            companyId:
              (location.state as ILocationState).companyId === -1
                ? undefined
                : (location.state as ILocationState).companyId,
            professionalId:
              (location.state as ILocationState).professionalId === -1
                ? undefined
                : (location.state as ILocationState).professionalId,
          },
        });

        setQuotes(data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }

    loadQuotes();
  }, []);

  return (
    <>
      {!!isLoading && (
        <ContainerAnimation className="content">
          <LoadingScreen />
        </ContainerAnimation>
      )}
      {!isLoading && (
        <ContainerAnimation className="content">
          {/* Cabeçalho */}
          <div className="bg-white">
            <div className="container">
              <div className="row align-items-center">
                {/* Título */}
                <div className="col-12 col-lg">
                  <h1>
                    <LinkButton className="fs-1" onClick={() => navigate(-1)}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Voltar</Tooltip>}
                      >
                        <i className="uil uil-arrow-left mr-2" />
                      </OverlayTrigger>
                    </LinkButton>
                    Previsão de Pagamento - {eventDate.format("DD/MM/YYYY")}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            {quotes.map((quote) => (
              <div
                key={quote.id}
                className={
                  "row-item mb-2 px-3 pt-3 border-left border-4 " +
                  (quote.payment?.method === PaymentMethod.Financing
                    ? "border-dark"
                    : "border-primary")
                }
              >
                <div className="row align-items-center">
                  {/* Informações do orçamento */}
                  <div className="col">
                    <div className="row align-items-center no-gutters">
                      <div className="col col-auto d-flex align-items-center flex-column pb-3 pr-3">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            quote.payment?.method ===
                              PaymentMethod.Financing ? (
                              <Tooltip>Financiamento</Tooltip>
                            ) : (
                              <Tooltip>Parcelamento</Tooltip>
                            )
                          }
                        >
                          {quote.payment?.method === PaymentMethod.Financing ? (
                            <div className="badge badge-dark rounded-circle py-2 fs-5 text-center">
                              <i className="uil uil-file-landscape-alt"></i>
                            </div>
                          ) : (
                            <div className="badge badge-primary rounded-circle py-2 fs-5 text-center">
                              <i className="uil uil-credit-card"></i>
                            </div>
                          )}
                        </OverlayTrigger>
                        <div>
                          <b>#{quote.id}</b>
                        </div>
                      </div>

                      {/* Nome do cliente */}
                      <div className="col col-md-5 col-xxl pb-3 px-1">
                        <p className="small opacity-75 mb-0">Cliente</p>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{quote.patient.name}</Tooltip>}
                        >
                          <b className="d-block text-md-truncate">
                            {quote.patient.name}
                          </b>
                        </OverlayTrigger>
                      </div>

                      {/* Nome do médico */}
                      <div className="col-12 col-md-6 col-xxl-2 pb-3 px-1 ml-auto">
                        <p className="small opacity-75 mb-0">Profissional</p>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              {quote.professional?.name ??
                                "PROFISSIONAL NÃO INFORMADO..."}
                            </Tooltip>
                          }
                        >
                          <b
                            className={
                              "d-block text-sm-truncate " +
                              (quote.professional?.name ??
                                "opacity-50 user-select-none")
                            }
                          >
                            {quote.professional?.name ?? "NÃO INFORMADO..."}
                          </b>
                        </OverlayTrigger>
                        <small>{quote.company?.name}</small>
                      </div>

                      <div className="col-12 col-xl-6 col-xxl-5">
                        <div className="row">
                          {/* Data do procedimento */}
                          <div className="col-12 col-sm-6 col-md-3 pb-3">
                            <p className="small opacity-75 mb-0 text-truncate">
                              Data do Procedimento
                            </p>
                            <b>{moment(quote.date).format("DD/MM/YYYY")}</b>
                          </div>

                          {/* Data limite */}
                          <div className="col-12 col-sm-6 col-md-3 pb-3">
                            <p className="small opacity-75 mb-0">Data Limite</p>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                quote.hasLimitDateAlert ? (
                                  <Tooltip>
                                    Orçamento está prestes a expirar
                                  </Tooltip>
                                ) : (
                                  <></>
                                )
                              }
                            >
                              <b
                                className={
                                  quote.hasLimitDateAlert ? "text-danger" : ""
                                }
                              >
                                {moment(quote.limitDate).format("DD/MM/YYYY")}
                              </b>
                            </OverlayTrigger>
                          </div>

                          {/* Valor do orçamento */}
                          <div className="col">
                            <div className="row">
                              <div className="col-6 col-md pb-3">
                                <p className="small opacity-75 mb-0">
                                  Valor de Compensação{" "}
                                  <PaymentInfo payment={quote.payment} />
                                </p>
                                <b>
                                  {formatPrice(quote.payment?.compensationValue)}
                                </b>
                              </div>
                              {/* ID pagamento */}
                              {userRole === RoleDescription.Admin &&
                                !!quote.payment.orderId && (
                                  <div className="col-6 col-md pb-3">
                                    <p className="small opacity-75 mb-0">
                                      Pagamento
                                    </p>
                                    <b className="d-block">
                                      #{quote.payment.orderId}
                                    </b>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Status do orçamento */}
                      <div className="col-12 col-sm-6 col-md-12 col-xl-6 col-xxl-2 pb-3 text-nowrap px-1">
                        <p className="small opacity-75 mb-0">
                          Status{" "}
                          {isLoading && (
                            <CircularProgress size={12} color="inherit" />
                          )}
                        </p>
                        <div>
                          <QuoteStatusInfo
                            quoteStatus={quote.quoteStatus}
                            paymentMethod={quote.payment.method}
                          />
                        </div>
                        <div className="display-6">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              quote.hasTerm ? (
                                <Tooltip>Termo Assinado</Tooltip>
                              ) : (
                                <Tooltip>Termo Não Assinado</Tooltip>
                              )
                            }
                          >
                            <i
                              className={`uil uil-file-edit-alt ${!quote.hasTerm ? "opacity-25" : "text-success"
                                }`}
                            ></i>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={
                              quote.hasAllDocumention ? (
                                <Tooltip>Documentos Aprovados</Tooltip>
                              ) : (
                                <Tooltip>Documentos Pendentes</Tooltip>
                              )
                            }
                          >
                            <i
                              className={`uil uil-file-upload ${!quote.hasAllDocumention
                                ? "opacity-25"
                                : "text-success"
                                }`}
                            ></i>
                          </OverlayTrigger>

                          {quote.hasCheckList && (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                quote.doneCheckList ? (
                                  <Tooltip>Checklist completo</Tooltip>
                                ) : (
                                  <Tooltip>Checklist incompleto</Tooltip>
                                )
                              }
                            >
                              <i
                                className={`uil uil-check-circle ${!quote.doneCheckList
                                  ? "opacity-25"
                                  : "text-success"
                                  }`}
                              ></i>
                            </OverlayTrigger>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Botão de "Detalhes" */}
                  <div className="col-12 col-sm-auto pb-3 position-relative">
                    <Link
                      to={"/orcamentos-profissional/orcamento-detalhes"}
                      className="btn btn-light btn-sm"
                      state={quote.id}
                    >
                      Detalhes
                    </Link>

                    {!!pendingItems.length && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Ver Pendências</Tooltip>}
                      >
                        <Badge className="animate__animated animate__pulse animate__infinite bg-danger rounded-circle position-absolute start-0 mt-n1 ms-2 p-1">
                          <i className="animate__animated animate__pulse animate__infinite uil uil-exclamation-circle text-white"></i>
                        </Badge>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            ))}

            <div className="p-3">
              <h4 className="text-end text-body mb-0">
                Valor Acumulado:{" "}
                <span className="text-primary">
                  {formatPrice((location.state as ILocationState).totalValue)}
                </span>
              </h4>
            </div>
          </div>
        </ContainerAnimation>
      )}
    </>
  );
};

export default ScheduleDetails;
