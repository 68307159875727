import {UseFormRegisterReturn} from "react-hook-form";

interface CardOptionProps {
  brand: string;
  register: UseFormRegisterReturn;
  checked: boolean;
  error?: boolean;
}

const CardOption = ({brand, register, error, ...rest}: CardOptionProps) => {
  return (
    <label>
      <input
        {...rest}
        {...register}
        id={brand}
        type="radio"
        className="checkbox-input card-flag-selector"
        value={brand}
        disabled
      />
      <span className={`checkbox-tile p-0 ${error && "border-danger"}`}>
        <span className="checkbox-label p-2">
          <img
            src={`${process.env.PUBLIC_URL}/bandeiras/${brand.toLowerCase()}.svg`}
            alt={`${brand} logo`}
            className="img-fluid"
            width={60}
          />
        </span>
      </span>
    </label>
  );
};

export default CardOption;
/*TODO: Adicionar SVG dessas bandeiras
AmericanExpress
Diners
JCB
Discover
Aura
*/