import moment from "moment";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { NavigateFunction } from "react-router-dom";

import { useAuth } from "providers/Auth";
import { api } from "services";
import {
  Quote,
  QuoteCreateData,
  QuoteReport,
  SearchDataQuoteProfessional,
  SimulatorParcel,
} from "types/quotes";
import { toast } from "utils/toast";

interface ProfessionalQuoteProviderProps {
  children: ReactNode;
}

interface ProfessionalQuoteProviderData {
  professionalPendingQuotes: Quote[];
  professionalPendingQuotesTotal: number;
  professionalPendingQuotesTotalPages: number;
  professionalPendingQuotesCurrentPage: number;
  professionalPendingQuotesFound: Quote[];
  professionalPendingQuotesFoundTotal: number;
  professionalPendingQuotesFoundTotalPages: number;
  professionalPendingQuotesFoundCurrentPage: number;

  getProfessionalPendingQuotes: (currentPage?: number) => Promise<void>;
  searchProfessionalPendingQuotes: (
    data: SearchDataQuoteProfessional,
    currentPage?: number
  ) => Promise<void>;
  setProfessionalPendingQuotesFound: React.Dispatch<
    React.SetStateAction<Quote[]>
  >;
  setProfessionalPendingQuotesCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;
  setProfessionalPendingQuotesFoundCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;

  professionalFinishedQuotes: Quote[];
  professionalFinishedQuotesTotal: number;
  professionalFinishedQuotesTotalPages: number;
  professionalFinishedQuotesCurrentPage: number;
  professionalFinishedQuotesFound: Quote[];
  professionalFinishedQuotesFoundTotal: number;
  professionalFinishedQuotesFoundTotalPages: number;
  professionalFinishedQuotesFoundCurrentPage: number;

  getProfessionalFinishedQuotes: (currentPage?: number) => Promise<void>;
  searchProfessionalFinishedQuotes: (
    data: SearchDataQuoteProfessional,
    currentPage?: number
  ) => Promise<void>;
  setProfessionalFinishedQuotesFound: React.Dispatch<
    React.SetStateAction<Quote[]>
  >;
  setProfessionalFinishedQuotesCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;
  setProfessionalFinishedQuotesFoundCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;

  professionalQuotesReport: QuoteReport[];
  professionalQuotesReportTotal: number;
  professionalQuotesReportTotalPages: number;
  professionalQuotesReportCurrentPage: number;
  professionalQuotesReportFound: QuoteReport[];
  professionalQuotesReportFoundTotal: number;
  professionalQuotesReportFoundTotalPages: number;
  professionalQuotesReportFoundCurrentPage: number;

  getProfessionalQuotesReport: (currentPage?: number) => Promise<void>;
  searchProfessionalQuotesReport: (
    data: SearchDataQuoteProfessional,
    currentPage?: number
  ) => Promise<void>;
  setProfessionalQuotesReportFound: React.Dispatch<
    React.SetStateAction<QuoteReport[]>
  >;
  setProfessionalQuotesReportCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;
  setProfessionalQuotesReportFoundCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;

  createProfessionalQuote: (
    data: QuoteCreateData,
    files: File[],
    onHide: () => void,
    navigate: NavigateFunction
  ) => Promise<void>;
  editProfessionalQuote: (
    data: Omit<QuoteCreateData, "fileTypes">,
    quoteId: number,
    onHide: () => void,
    setQuote: React.Dispatch<React.SetStateAction<Quote>>
  ) => Promise<void>;

  searchData: SearchDataQuoteProfessional;
  setSearchData: React.Dispatch<
    React.SetStateAction<SearchDataQuoteProfessional>
  >;

  isLoadingProfessionalPendingQuotes: boolean;
  isLoadingProfessionalFinishedQuotes: boolean;
  isLoadingProfessionalQuotesReport: boolean;
  isLoading: boolean;

  simulatedQuotePayment: SimulatorParcel;
  setSimulatedQuotePayment: React.Dispatch<
    React.SetStateAction<SimulatorParcel>
  >;

  professionalQuotesReportGlobalRef: any;
  setProfessionalQuotesReportGlobalRef: React.Dispatch<any>;
}

export const ProfessionalQuoteContext =
  createContext<ProfessionalQuoteProviderData>(
    {} as ProfessionalQuoteProviderData
  );

export const ProfessionalQuoteProvider = ({
  children,
}: ProfessionalQuoteProviderProps) => {
  const { token } = useAuth();
  const professionalQuotesLimit = 10;

  //#region provider states professionalPendingQuotes
  const [professionalPendingQuotes, setProfessionalPendingQuotes] = useState<
    Quote[]
  >([]);

  const [professionalPendingQuotesTotal, setProfessionalPendingQuotesTotal] =
    useState(0);

  const [
    professionalPendingQuotesTotalPages,
    setProfessionalPendingQuotesTotalPages,
  ] = useState(0);

  const [
    professionalPendingQuotesCurrentPage,
    setProfessionalPendingQuotesCurrentPage,
  ] = useState(1);

  const [professionalPendingQuotesFound, setProfessionalPendingQuotesFound] =
    useState<Quote[]>([]);

  const [
    professionalPendingQuotesFoundTotal,
    setProfessionalPendingQuotesFoundTotal,
  ] = useState(0);

  const [
    professionalPendingQuotesFoundTotalPages,
    setProfessionalPendingQuotesFoundTotalPages,
  ] = useState(0);

  const [
    professionalPendingQuotesFoundCurrentPage,
    setProfessionalPendingQuotesFoundCurrentPage,
  ] = useState(1);
  //#endregion

  //#region provider states professionalFinishedQuotes
  const [professionalFinishedQuotes, setProfessionalFinishedQuotes] = useState<
    Quote[]
  >([]);

  const [professionalFinishedQuotesTotal, setProfessionalFinishedQuotesTotal] =
    useState(0);

  const [
    professionalFinishedQuotesTotalPages,
    setProfessionalFinishedQuotesTotalPages,
  ] = useState(0);

  const [
    professionalFinishedQuotesCurrentPage,
    setProfessionalFinishedQuotesCurrentPage,
  ] = useState(1);

  const [professionalFinishedQuotesFound, setProfessionalFinishedQuotesFound] =
    useState<Quote[]>([]);

  const [
    professionalFinishedQuotesFoundTotal,
    setProfessionalFinishedQuotesFoundTotal,
  ] = useState(0);

  const [
    professionalFinishedQuotesFoundTotalPages,
    setProfessionalFinishedQuotesFoundTotalPages,
  ] = useState(0);

  const [
    professionalFinishedQuotesFoundCurrentPage,
    setProfessionalFinishedQuotesFoundCurrentPage,
  ] = useState(1);
  //#endregion

  //#region provider states professionalQuotesReport
  const [professionalQuotesReport, setProfessionalQuotesReport] = useState<
    QuoteReport[]
  >([]);

  const [professionalQuotesReportTotal, setProfessionalQuotesReportTotal] =
    useState(0);

  const [
    professionalQuotesReportTotalPages,
    setProfessionalQuotesReportTotalPages,
  ] = useState(0);

  const [
    professionalQuotesReportCurrentPage,
    setProfessionalQuotesReportCurrentPage,
  ] = useState(1);

  const [professionalQuotesReportFound, setProfessionalQuotesReportFound] =
    useState<QuoteReport[]>([]);

  const [
    professionalQuotesReportFoundTotal,
    setProfessionalQuotesReportFoundTotal,
  ] = useState(0);

  const [
    professionalQuotesReportFoundTotalPages,
    setProfessionalQuotesReportFoundTotalPages,
  ] = useState(0);

  const [
    professionalQuotesReportFoundCurrentPage,
    setProfessionalQuotesReportFoundCurrentPage,
  ] = useState(1);
  //#endregion

  const [searchData, setSearchData] = useState(
    {} as SearchDataQuoteProfessional
  );

  const [
    professionalQuotesReportGlobalRef,
    setProfessionalQuotesReportGlobalRef,
  ] = useState(null);

  const [
    isLoadingProfessionalPendingQuotes,
    setIsLoadingProfessionalPendingQuotes,
  ] = useState(false);

  const [
    isLoadingProfessionalFinishedQuotes,
    setIsLoadingProfessionalFinishedQuotes,
  ] = useState(false);

  const [
    isLoadingProfessionalQuotesReport,
    setIsLoadingProfessionalQuotesReport,
  ] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [simulatedQuotePayment, setSimulatedQuotePayment] =
    useState<SimulatorParcel>({} as SimulatorParcel);

  const searchProfessionalPendingQuotes = async (
    data: SearchDataQuoteProfessional,
    currentPage = 1
  ) => {
    setIsLoadingProfessionalPendingQuotes(true);
    setProfessionalPendingQuotesFoundCurrentPage(currentPage);

    const {
      patientName,
      beginDate,
      endDate,
      range,
      idQuote,
      chooseDate,
      paymentMethod,
      beginDateCreate,
      endDateCreate,
      companyId,
    } = data;

    try {
      const response = await api.get("Quote/search", {
        params: {
          idQuote: idQuote,
          page: currentPage,
          size: professionalQuotesLimit,
          patientName: patientName ? patientName : undefined,
          beginDate: beginDate
            ? moment(beginDate).format("YYYY-MM-DD")
            : undefined,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
          beginDateCreate: beginDateCreate
            ? moment(beginDateCreate).format("YYYY-MM-DD")
            : undefined,
          endDateCreate: endDateCreate
            ? moment(endDateCreate).format("YYYY-MM-DD")
            : undefined,
          minPrice: range ? range[0] ?? 0 : undefined,
          maxPrice: range ? range[1] ?? 100000 : undefined,
          onlyOpened: true,
          chooseDate: chooseDate,
          creditMethod: paymentMethod ? paymentMethod?.credit : undefined,
          financingMethod: paymentMethod ? paymentMethod?.financing : undefined,
          companyId: companyId === -1 ? undefined : companyId,
        },
      });

      setIsLoadingProfessionalPendingQuotes(false);
      setProfessionalPendingQuotesFound(response.data);

      setProfessionalPendingQuotesFoundTotal(
        Number(response.headers["x-total-count"])
      );

      setProfessionalPendingQuotesFoundTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / professionalQuotesLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoadingProfessionalPendingQuotes(false);
    }
  };

  const searchProfessionalFinishedQuotes = async (
    data: SearchDataQuoteProfessional,
    currentPage = 1
  ) => {
    setIsLoadingProfessionalFinishedQuotes(true);
    setProfessionalFinishedQuotesFoundCurrentPage(currentPage);

    const {
      patientName,
      beginDate,
      endDate,
      beginDateCreate,
      endDateCreate,
      range,
      idQuote,
      chooseDate,
      paymentMethod,
      companyId,
    } = data;

    try {
      const response = await api.get("Quote/search", {
        params: {
          idQuote: idQuote,
          page: currentPage,
          size: professionalQuotesLimit,
          patientName: patientName ? patientName : undefined,
          beginDate: beginDate
            ? moment(beginDate).format("YYYY-MM-DD")
            : undefined,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
          beginDateCreate: beginDateCreate
            ? moment(beginDateCreate).format("YYYY-MM-DD")
            : undefined,
          endDateCreate: endDateCreate
            ? moment(endDateCreate).format("YYYY-MM-DD")
            : undefined,
          minPrice: range ? range[0] ?? 0 : undefined,
          maxPrice: range ? range[1] ?? 100000 : undefined,
          onlyOpened: false,
          chooseDate: chooseDate,
          creditMethod: paymentMethod ? paymentMethod?.credit : undefined,
          financingMethod: paymentMethod ? paymentMethod?.financing : undefined,
          companyId: companyId === -1 ? undefined : companyId,
        },
      });

      setIsLoadingProfessionalFinishedQuotes(false);
      setProfessionalFinishedQuotesFound(response.data);

      setProfessionalFinishedQuotesFoundTotal(
        Number(response.headers["x-total-count"])
      );

      setProfessionalFinishedQuotesFoundTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / professionalQuotesLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoadingProfessionalFinishedQuotes(false);
    }
  };

  const searchProfessionalQuotesReport = async (
    data: SearchDataQuoteProfessional,
    currentPage = 1
  ) => {
    setIsLoadingProfessionalQuotesReport(true);
    setProfessionalQuotesReportFoundCurrentPage(currentPage);

    const {
      patientName,
      beginDate,
      endDate,
      beginDateCreate,
      endDateCreate,
      range,
      idQuote,
      chooseDate,
      paymentMethod,
      companyId,
    } = data;

    try {
      const response = await api.get("Quote/report", {
        params: {
          idQuote: idQuote,
          page: currentPage,
          size: professionalQuotesLimit,
          patientName: patientName ? patientName : undefined,
          beginDate: beginDate
            ? moment(beginDate).format("YYYY-MM-DD")
            : undefined,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
          beginDateCreate: beginDateCreate
            ? moment(beginDateCreate).format("YYYY-MM-DD")
            : undefined,
          endDateCreate: endDateCreate
            ? moment(endDateCreate).format("YYYY-MM-DD")
            : undefined,
          minPrice: range ? range[0] ?? 0 : undefined,
          maxPrice: range ? range[1] ?? 100000 : undefined,
          onlyOpened: false,
          chooseDate: chooseDate,
          creditMethod: paymentMethod ? paymentMethod?.credit : undefined,
          financingMethod: paymentMethod ? paymentMethod?.financing : undefined,
          companyId: companyId === -1 ? undefined : companyId,
        },
      });

      setIsLoadingProfessionalQuotesReport(false);
      setProfessionalQuotesReportFound(response.data);

      setProfessionalQuotesReportFoundTotal(
        Number(response.headers["x-total-count"])
      );

      setProfessionalQuotesReportFoundTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / professionalQuotesLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoadingProfessionalQuotesReport(false);
    }
  };

  const getProfessionalPendingQuotes = async (currentPage = 1) => {
    setIsLoadingProfessionalPendingQuotes(true);

    try {
      const response = await api.get("Quote/search", {
        params: {
          page: currentPage,
          size: professionalQuotesLimit,
          onlyOpened: true,
        },
      });

      setIsLoadingProfessionalPendingQuotes(false);
      setProfessionalPendingQuotes(response.data);
      setProfessionalPendingQuotesTotal(
        Number(response.headers["x-total-count"])
      );

      setProfessionalPendingQuotesTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / professionalQuotesLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoadingProfessionalPendingQuotes(false);
    }
  };

  const getProfessionalFinishedQuotes = async (currentPage = 1) => {
    setIsLoadingProfessionalFinishedQuotes(true);

    try {
      const response = await api.get("Quote/search", {
        params: {
          page: currentPage,
          size: professionalQuotesLimit,
          onlyOpened: false,
        },
      });

      setIsLoadingProfessionalFinishedQuotes(false);
      setProfessionalFinishedQuotes(response.data);
      setProfessionalFinishedQuotesTotal(
        Number(response.headers["x-total-count"])
      );

      setProfessionalFinishedQuotesTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / professionalQuotesLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoadingProfessionalFinishedQuotes(false);
    }
  };

  const getProfessionalQuotesReport = async (currentPage = 1) => {
    setIsLoadingProfessionalQuotesReport(true);

    try {
      const response = await api.get("Quote/report", {
        params: {
          page: currentPage,
          size: professionalQuotesLimit,
          onlyOpened: false,
        },
      });

      setIsLoadingProfessionalQuotesReport(false);
      setProfessionalQuotesReport(response.data);
      setProfessionalQuotesReportTotal(
        Number(response.headers["x-total-count"])
      );

      setProfessionalQuotesReportTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / professionalQuotesLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoadingProfessionalQuotesReport(false);
    }
  };

  const createProfessionalQuote = async (
    data: QuoteCreateData,
    files: File[],
    onHide: () => void,
    navigate: NavigateFunction
  ) => {
    const body = {
      ...data,
      company: { id: data.company.value, name: data.company.label },
      patient: { id: data.patient.value, name: data.patient.label },
      professional: data.professional?.value
        ? { id: data.professional.value, name: data.professional.label }
        : data.professional,
      date: moment(data.date).toISOString(true),
      files: [],
      priceTable: undefined,
      payment: {
        value: Number(data.priceValue?.replace(/\./g, "").replace(",", ".")),
        method: data.paymentMethod?.value,
        parcelQtd: data.parcelQtd?.value,
        feeMonthly: data.feeMonthly
          ? Number(data.feeMonthly?.replace(/\./g, "").replace(",", "."))
          : null,
        feeAnnual: data.feeAnnual
          ? Number(data.feeAnnual?.replace(/\./g, "").replace(",", "."))
          : null,
        parcelValue: data.parcelValue
          ? Number(data.parcelValue?.replace(/\./g, "").replace(",", "."))
          : null,
        finalValue: data.finalValue
          ? Number(data.finalValue?.replace(/\./g, "").replace(",", "."))
          : null,
        paymentFeeOwner: data.paymentFeeOwner ?? undefined,
        priceTable: data.priceTable,
      },
    };

    delete body.priceValue;
    delete body.paymentMethod;
    delete body.parcelQtd;
    delete body.feeMonthly;
    delete body.feeAnnual;
    delete body.parcelValue;
    delete body.finalValue;
    delete body.paymentFeeOwner;

    try {
      const response = await api.post("/Quote", body);

      if (files.length && body.fileTypes?.length) {
        try {
          for (let i = 0; i < files.length; i++) {
            const formData = new FormData();

            formData.append("document", files[i]);
            formData.append("idQuote", response.data.id);
            formData.append("documentType", body.fileTypes[i].value);

            await api.post("/FileAttachment", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }

          await toast.fire({
            icon: "success",
            title: "Arquivos anexados com sucesso!",
          });
        } catch (error) {
          toast.fire({
            icon: "error",
            title: "Erro ao anexar documentos.",
          });
        }
      }

      onHide();

      toast.fire({
        icon: "success",
        title: "Orçamento cadastrado com sucesso!",
      });

      navigate("/orcamentos-profissional/orcamento-detalhes", {
        state: response.data.id,
      });
    } catch (error: any) {
      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const editProfessionalQuote = async (
    data: Omit<QuoteCreateData, "fileTypes">,
    quoteId: number,
    onHide: () => void,
    setQuote: React.Dispatch<React.SetStateAction<Quote>>
  ) => {
    setIsLoading(true);

    const body = {
      ...data,
      company: { id: data.company.value, name: data.company.label },
      patient: {
        id: data.patient.value,
        name: data.patient.label,
      },
      professional: data.professional?.value
        ? { id: data.professional.value, name: data.professional.label }
        : data.professional,
      date: moment(data.date).toISOString(true),
      files: [],
      payment: {
        value: Number(data.priceValue?.replace(/\./g, "").replace(",", ".")),
        method: data.paymentMethod?.value,
        parcelQtd: data.parcelQtd?.value,
        feeMonthly: data.feeMonthly
          ? Number(data.feeMonthly?.replace(/\./g, "").replace(",", "."))
          : null,
        feeAnnual: data.feeAnnual
          ? Number(data.feeAnnual?.replace(/\./g, "").replace(",", "."))
          : null,
        parcelValue: data.parcelValue
          ? Number(data.parcelValue?.replace(/\./g, "").replace(",", "."))
          : null,
        finalValue: data.finalValue
          ? Number(data.finalValue?.replace(/\./g, "").replace(",", "."))
          : null,
        compensationValue: data.compensationValue
          ? Number(data.compensationValue?.replace(/\./g, "").replace(",", "."))
          : null,
        paymentLiquidationDate: moment(data.paymentLiquidationDate).toISOString(
          true
        ),
        paymentFeeOwner: data.paymentFeeOwner ?? undefined,
      },
    };

    delete body.priceValue;
    delete body.paymentMethod;
    delete body.parcelQtd;
    delete body.feeMonthly;
    delete body.feeAnnual;
    delete body.parcelValue;
    delete body.finalValue;

    try {
      await api.put(`/Quote/${quoteId}`, body);

      setIsLoading(false);

      onHide();

      toast.fire({
        icon: "success",
        title: "Dados atualizados com sucesso!",
      });

      const response = await api.get(`/Quote/${quoteId}`);
      setQuote(response.data);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  useEffect(() => {
    setSearchData({} as SearchDataQuoteProfessional);
  }, [token]);

  return (
    <ProfessionalQuoteContext.Provider
      value={{
        professionalPendingQuotes,
        professionalPendingQuotesTotal,
        professionalPendingQuotesTotalPages,
        professionalPendingQuotesCurrentPage,
        professionalPendingQuotesFound,
        professionalPendingQuotesFoundTotal,
        professionalPendingQuotesFoundTotalPages,
        professionalPendingQuotesFoundCurrentPage,

        professionalFinishedQuotes,
        professionalFinishedQuotesTotal,
        professionalFinishedQuotesTotalPages,
        professionalFinishedQuotesCurrentPage,
        professionalFinishedQuotesFound,
        professionalFinishedQuotesFoundTotal,
        professionalFinishedQuotesFoundTotalPages,
        professionalFinishedQuotesFoundCurrentPage,

        professionalQuotesReport,
        professionalQuotesReportTotal,
        professionalQuotesReportTotalPages,
        professionalQuotesReportCurrentPage,
        professionalQuotesReportFound,
        professionalQuotesReportFoundTotal,
        professionalQuotesReportFoundTotalPages,
        professionalQuotesReportFoundCurrentPage,

        searchData,
        setSearchData,

        isLoadingProfessionalPendingQuotes,
        isLoadingProfessionalFinishedQuotes,
        isLoadingProfessionalQuotesReport,
        isLoading,

        getProfessionalPendingQuotes,
        searchProfessionalPendingQuotes,
        searchProfessionalFinishedQuotes,
        searchProfessionalQuotesReport,
        getProfessionalFinishedQuotes,
        getProfessionalQuotesReport,
        createProfessionalQuote,
        editProfessionalQuote,

        setProfessionalPendingQuotesCurrentPage,
        setProfessionalPendingQuotesFoundCurrentPage,
        setProfessionalPendingQuotesFound,

        setProfessionalFinishedQuotesCurrentPage,
        setProfessionalFinishedQuotesFoundCurrentPage,
        setProfessionalFinishedQuotesFound,

        setProfessionalQuotesReportCurrentPage,
        setProfessionalQuotesReportFoundCurrentPage,
        setProfessionalQuotesReportFound,

        simulatedQuotePayment,
        setSimulatedQuotePayment,

        professionalQuotesReportGlobalRef,
        setProfessionalQuotesReportGlobalRef,
      }}
    >
      {children}
    </ProfessionalQuoteContext.Provider>
  );
};

export const useProfessionalQuote = () => useContext(ProfessionalQuoteContext);
