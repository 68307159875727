import React, {useEffect} from "react";

import logo from "assets/logo-docttorpay-horizontal.svg";
import {PaymentFeeOwner, PaymentMethod} from "types/enums";
import {SimulatorParcel} from "types/quotes";

import SimulatorParcelCard from "../SimulatorParcelCard";

interface PDFSimulatorParcelsProps {
  method: PaymentMethod;
  simulatorParcels: SimulatorParcel[];
  company: {value: number; label: string};
  value: string | {value: number; label: string};
  currentPaymentFeeOwner: PaymentFeeOwner | null;
  priceTable: {value: number; label: string} | null;
}

const PDFSimulatorParcels = ({
  method,
  simulatorParcels,
  company,
  value,
  priceTable = null,
  currentPaymentFeeOwner = null,
}: PDFSimulatorParcelsProps) => {
  return (
    <>
      <div className="w-full logo">
        <img src={logo} alt="Logo DocttorPay" />
      </div>
      <h5 className="text-center">
        Simulação Da{" "}
        {priceTable ? priceTable.label : company ? company.label : ""}
      </h5>
      {currentPaymentFeeOwner && (
        <h5 className="fw-normal text-center">
          Taxa assumida pelo{" "}
          {currentPaymentFeeOwner && (
            <b>{PaymentFeeOwner[currentPaymentFeeOwner ?? 1]}</b>
          )}
        </h5>
      )}
      <h5 className="fw-normal mb-4 text-center">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        Valor Total: <b>R$ {typeof value == "object" ? value.value : value}</b>
      </h5>
      {simulatorParcels?.map((simulatorParcel) => (
        <React.Fragment key={simulatorParcel.parcelQtd}>
          <SimulatorParcelCard
            simulatorParcel={simulatorParcel}
            company={company}
            showAddQuote={false}
          />
          <hr />
        </React.Fragment>
      ))}
      {method == PaymentMethod.Financing && (
        <footer className="fixed-bottom p-3">
          <small>
            *Informações de uso interno - este relatório não deve ser enviado ao
            usuário final
          </small>
          <br />
          <small>*Valores de referência. Crédito sujeito à aprovação.</small>
          <br />
          <small>
            *As taxas podem variar de 2% a.m. até 5% a.m. - elas dependem da
            quantidade de parcelas, score do solicitante, valor do seguro, local
            de moradia e trabalho do solicitante e tarifa de IOF conforme
            legislação vigente.
          </small>
          <br />
          <small>
            *Não deve ser enviado print da simulação para o paciente, os valores
            são de referência e podem ter alterações de acordo com o perfil
            financeiro do paciente. Apenas usar a simulação como base e informar
            que a parcela é a partir do valor simulado.
          </small>
        </footer>
      )}
    </>
  );
};

export default PDFSimulatorParcels;
