/* eslint @typescript-eslint/ban-ts-comment: 0 */

import {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import useClipboard from "react-use-clipboard";

import Button from "components/Button";
// import Input from "components/Input";
import LoadingScreen from "components/LoadingScreen";
import {useUser} from "providers/User";
import {api} from "services";
import {PaymentLinkAction} from "types/enums";
import {toast} from "utils/toast";

interface PaymentLinkProps {
  onHide: () => void;
  quoteId: number;
  linkAction: number;
}

const PaymentLink = ({onHide, quoteId, linkAction}: PaymentLinkProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [paymentLink, setPaymentLink] = useState("");
  const [paymentLinkText, setPaymentLinkText] = useState("");
  const [paymentLinkTextHtml, setPaymentLinkTextHtml] = useState("");

  let paymentLinkPath = "";
  if (paymentLink) {
    paymentLinkPath = new URL(paymentLink).pathname;
  }

  const {userData} = useUser();

  const [isCopied, setIsCopied] = useClipboard(paymentLinkText, {
    successDuration: 2000,
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/Quote/${quoteId}/paymentLink`);

        const formattedHtml = response.data.text
          .replace(/\n/g, "<br>")
          .replace(/https(.*?)<br>/, "<strong>$&</strong>");

        setPaymentLink(response.data.url);
        setPaymentLinkText(response.data.text);
        setPaymentLinkTextHtml(formattedHtml);
      } catch (error) {
        console.error(error);

        onHide();

        // @ts-ignore
        if (error.response.data.Profissional) {
          toast.fire({
            icon: "error",
            title: "Ocorreu um erro, defina um profissional.",
          });
          // @ts-ignore
        } else if (error.response.data.Empresa) {
          toast.fire({
            icon: "error",
            // @ts-ignore
            title: error.responde.data.Empresa,
          });
          // @ts-ignore
        } else if (error.response.data.TabelaPreco) {
          toast.fire({
            icon: "error",
            title: "Ocorreu um erro, empresa sem tabela preço.",
          });
          // @ts-ignore
        } else if (error.response.data.IdEmpresa) {
          toast.fire({
            icon: "error",
            title: "Ocorreu um erro, empresa não cadastrada.",
          });
        } else {
          toast.fire({
            icon: "error",
            title: "Ocorreu um erro, tente novamente.",
          });
        }
      }
    })().then(() => setIsLoading(false));
  }, []);

  const handleSendMsg = async () => {
    try {
      await api.post("/WhatsApp/SendMsg", {
        phone: userData?.mobilePhone,
        ddd: userData?.dddMobilePhone,
        message: paymentLinkText,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Modal.Body>
          {/* {linkAction !== PaymentLinkAction.CopyPay && (
            <>
              <div>
                <h5 className="d-flex align-items-center mb-4">
                  <span className="badge bg-success rounded-circle mr-2">
                    <i className="uil uil-share-alt text-white display-6"></i>
                  </span>
                  Compartilhe por WhatsApp ou e-mail
                </h5>

                <form>
                  <div className="form-group">
                    <Input label="WhatsApp:" placeholder="(99) 99999-9999" />
                  </div>

                  <div className="form-group">
                    <Input label="E-mail:" placeholder="nome@email.com" />
                  </div>

                  <div className="text-end">
                    <Button className="btn-primary btn-sm">
                      <i className="uil uil-message mr-2" />
                      Enviar
                    </Button>
                  </div>
                </form>
              </div>

              <hr className="my-4" />
            </>
          )} */}

          <div>
            <h5 className="d-flex align-items-center mb-4">
              <span className="badge bg-secondary rounded-circle mr-2">
                <i className="uil uil-link text-white display-6"></i>
              </span>
              Link compartilhável
            </h5>

            <div>
              <div className="form-group">
                {/* <Input readOnly defaultValue={paymentLink} /> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: paymentLinkTextHtml,
                  }}
                ></div>
              </div>

              <div className="text-end">
                {linkAction === PaymentLinkAction.Copy ||
                  linkAction === PaymentLinkAction.CopyPay ? (
                  <>
                    <Button
                      className="btn-outline-success btn-sm mr-2"
                      onClick={handleSendMsg}
                    >
                      <i className="uil uil-whatsapp mr-2" />
                      Enviar mensagem
                    </Button>

                    <Button
                      className="btn-outline-success btn-sm mr-2"
                      onClick={setIsCopied}
                    >
                      <i className="uil uil-copy mr-2" />
                      Copiar
                    </Button>
                  </>
                ) : null}

                {/* <a
                  className="btn btn-primary btn-sm"
                  href={paymentLink}
                  target="_blank"
                  rel="noreferrer noopener">
                  <i className="uil uil-transaction mr-2" />
                  Pagar
                </a> */}

                {linkAction === PaymentLinkAction.CopyPay && (
                  <Link
                    to={`../${paymentLinkPath}`}
                    className="btn btn-primary btn-sm"
                  >
                    Pagar
                  </Link>
                )}

                <small
                  className={`${!isCopied && "invisible"
                    } d-block text-primary text-decoration-underline mt-1`}
                >
                  Link Copiado!
                </small>
              </div>
            </div>
          </div>
        </Modal.Body>
      )}
    </>
  );
};

export default PaymentLink;
