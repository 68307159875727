import Pagination from "@mui/material/Pagination";
import moment from "moment";
import {useEffect, useRef, useState} from "react";

import EmptyList from "components/EmptyList";
import ListCardSkeleton from "components/ListCardSkeleton";
import {usePatientQuote} from "providers/Quote/PatientQuote";

import PatientQuoteReportCard from "../PatientQuoteReportCard";

const PatientQuoteReportList = () => {
  const patientQuotesReportRef = useRef<HTMLDivElement>(null);
  const patientQuotesReportListRef = useRef<HTMLDivElement>(null);

  const {
    patientQuotesReport,
    patientQuotesReportFound,
    patientQuotesReportTotalPages,
    patientQuotesReportFoundTotalPages,
    searchData,
    setSearchData,
    isLoadingPatientQuotesReport,
    patientQuotesReportCurrentPage,
    patientQuotesReportFoundCurrentPage,
    getPatientQuotesReport,
    searchPatientQuotesReport,
    setPatientQuotesReportCurrentPage,
    setPatientQuotesReportFoundCurrentPage,
    setPatientQuotesReportGlobalRef,
  } = usePatientQuote();

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPatientQuotesReportCurrentPage(value);

    getPatientQuotesReport(value);

    patientQuotesReportRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleSearchPagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPatientQuotesReportFoundCurrentPage(value);
    console.log(value);

    searchPatientQuotesReport(searchData, value);

    patientQuotesReportRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const notShowFilters = () => {
    if (!Object.keys(searchData).length) {
      return true;
    }

    const paymentMethodValidation =
      !searchData.paymentMethod ||
      !Object.keys(searchData.paymentMethod).length ||
      (!searchData.paymentMethod?.credit && !searchData.paymentMethod?.financing);

    const rangeValidation =
      !searchData.range ||
      (searchData.range[0] === 0 && searchData.range[1] === 100000);

    if (
      !searchData.beginDate &&
      !searchData.endDate &&
      !searchData.beginDateCreate &&
      !searchData.endDateCreate &&
      !searchData.chooseDate &&
      !searchData.professionalName &&
      !searchData.idQuote &&
      paymentMethodValidation &&
      rangeValidation
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getPatientQuotesReport(patientQuotesReportCurrentPage);

    setTimeout(() => {
      setPatientQuotesReportGlobalRef(patientQuotesReportListRef.current);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!Object.keys(searchData).length) {
      return;
    }
    searchPatientQuotesReport(searchData);
  }, [searchData]);

  useEffect(() => {
    if (!!isLoadingPatientQuotesReport) {
      return;
    }

    setPatientQuotesReportGlobalRef(patientQuotesReportListRef.current);
  }, [isLoadingPatientQuotesReport]);

  return (
    <>
      {notShowFilters() ? (
        <></>
      ) : (
        <div className="bg-white shadow-sm p-3 rounded-xl mb-3">
          <div className="row no-gutters">
            <div className="col col-auto pr-2 pt-1">
              <small className="text-uppercase">Filtros:</small>
            </div>

            {/* Filtros */}
            <div className="col">
              <div className="row g-2">
                {/* Id Orçamento */}
                {!!Object.keys(searchData).length &&
                  !!searchData.idQuote && (
                    <div className="col col-auto">
                      <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                        <a
                          className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                          onClick={() =>
                            setSearchData({
                              ...searchData,
                              idQuote: "",
                            })
                          }
                        >
                          <i className="uil uil-times"></i>
                        </a>
                        <label className="small text-muted mb-0 mx-1">
                          Id do Orçamento:
                        </label>
                        <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                          {searchData.idQuote}
                        </div>
                      </div>
                    </div>
                  )}

                {/* Tipo de Pagamento */}
                {!!Object.keys(searchData).length &&
                  (searchData.paymentMethod?.credit ||
                    searchData.paymentMethod?.financing) && (
                    <div className="col col-auto">
                      <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                        <a
                          onClick={() =>
                            setSearchData({
                              ...searchData,
                              paymentMethod: {
                                credit: false,
                                financing: false,
                              },
                            })
                          }
                          className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                        >
                          <i className="uil uil-times"></i>
                        </a>
                        <label className="small text-muted mb-0 mx-1">
                          Tipo de Pagamento
                        </label>
                        {searchData.paymentMethod?.financing && (
                          <a
                            className="bg-dark rounded px-2 border text-white shadow-sm mx-1"
                            style={{cursor: "pointer"}}
                            onClick={() =>
                              setSearchData({
                                ...searchData,
                                paymentMethod: {
                                  credit: searchData.paymentMethod?.credit,
                                  financing: false,
                                },
                              })
                            }
                          >
                            <i className="uil uil-credit-card"></i>{" "}
                            Financiamento
                          </a>
                        )}
                        {searchData.paymentMethod?.credit && (
                          <a
                            className="bg-primary rounded px-2 border text-white shadow-sm mx-1"
                            style={{cursor: "pointer"}}
                            onClick={() =>
                              setSearchData({
                                ...searchData,
                                paymentMethod: {
                                  credit: false,
                                  financing:
                                    searchData.paymentMethod?.financing,
                                },
                              })
                            }
                          >
                            <i className="uil uil-file-landscape-alt"></i>{" "}
                            Parcelamento
                          </a>
                        )}
                      </div>
                    </div>
                  )}

                {/* Range Valor */}
                {!!searchData.range &&
                  searchData.range.toString() !==
                  [0, 100000].toString() && (
                    <div className="col col-auto">
                      <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                        <a
                          onClick={() =>
                            setSearchData({
                              ...searchData,
                              range: [0, 100000],
                            })
                          }
                          className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                        >
                          <i className="uil uil-times"></i>
                        </a>
                        <label className="small text-muted mb-0 mx-1">
                          Valor entre:
                        </label>
                        <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                          R$ {searchData.range[0]} - R${" "}
                          {searchData.range[1]}
                        </div>
                      </div>
                    </div>
                  )}

                {/* Data Criação de: */}
                {!!searchData.beginDateCreate && (
                  <div className="col col-auto">
                    <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                      <a
                        onClick={() =>
                          setSearchData({
                            ...searchData,
                            beginDateCreate: null,
                          })
                        }
                        className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                      >
                        <i className="uil uil-times"></i>
                      </a>
                      <label className="small text-muted mb-0 mx-1">
                        Data Criação de:
                      </label>
                      <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                        {moment(searchData.beginDateCreate).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* Data Criação até: */}
                {!!searchData.endDateCreate && (
                  <div className="col col-auto">
                    <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                      <a
                        onClick={() =>
                          setSearchData({
                            ...searchData,
                            endDateCreate: null,
                          })
                        }
                        className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                      >
                        <i className="uil uil-times"></i>
                      </a>
                      <label className="small text-muted mb-0 mx-1">
                        Data Criação até:
                      </label>
                      <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                        {moment(searchData.endDateCreate).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Data de: */}
                {!!searchData.beginDate && (
                  <div className="col col-auto">
                    <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                      <a
                        onClick={() =>
                          setSearchData({
                            ...searchData,
                            beginDate: null,
                          })
                        }
                        className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                      >
                        <i className="uil uil-times"></i>
                      </a>
                      <label className="small text-muted mb-0 mx-1">
                        Data de:
                      </label>
                      <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                        {moment(searchData.beginDate).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>
                )}
                {/* Data até: */}
                {!!searchData.endDate && (
                  <div className="col col-auto">
                    <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                      <a
                        onClick={() =>
                          setSearchData({
                            ...searchData,
                            endDate: null,
                          })
                        }
                        className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                      >
                        <i className="uil uil-times"></i>
                      </a>
                      <label className="small text-muted mb-0 mx-1">
                        Data até:
                      </label>
                      <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                        {moment(searchData.endDate).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Carregando lista de orçamentos */}
      {!!isLoadingPatientQuotesReport &&
        [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

      {/* Lista de orçamentos */}
      {!Object.values(searchData).some((currentValue) => !!currentValue) && (
        <>
          {!isLoadingPatientQuotesReport && (
            <div ref={patientQuotesReportListRef}>
              {!!patientQuotesReport.length &&
                patientQuotesReport?.map((quote) => (
                  <PatientQuoteReportCard key={quote.id} quote={quote} />
                ))}

              {/* Lista de orçamentos vazia */}
              {!patientQuotesReport.length && (
                <EmptyList
                  icon="uil-bill"
                  title="Nenhum orçamento cadastrado"
                  subtitle="Entre na tela de orçamentos e adicione um orçamento para aprovação"
                />
              )}
            </div>
          )}
          {patientQuotesReportTotalPages > 1 && (
            <Pagination
              color="primary"
              size="large"
              hidePrevButton
              hideNextButton
              count={patientQuotesReportTotalPages}
              page={patientQuotesReportCurrentPage}
              onChange={handlePagination}
              className="mt-4 d-flex justify-content-center"
            />
          )}
        </>
      )}

      {/* Lista de orçamentos pesquisados */}
      {!!Object.values(searchData).some((currentValue) => !!currentValue) && (
        <>
          {!isLoadingPatientQuotesReport && (
            <div ref={patientQuotesReportListRef}>
              {!!patientQuotesReportFound.length &&
                patientQuotesReportFound?.map((quote) => (
                  <PatientQuoteReportCard key={quote.id} quote={quote} />
                ))}

              {/* Lista de orçamentos vazia */}
              {!patientQuotesReportFound.length && (
                <EmptyList
                  icon="uil-bill"
                  title="Nenhum orçamento encontrado"
                />
              )}
            </div>
          )}
          {patientQuotesReportFoundTotalPages > 1 && (
            <Pagination
              color="primary"
              size="large"
              hidePrevButton
              hideNextButton
              count={patientQuotesReportFoundTotalPages}
              page={patientQuotesReportFoundCurrentPage}
              onChange={handleSearchPagination}
              className="mt-4 d-flex justify-content-center"
            />
          )}
        </>
      )}
    </>
  );
};

export default PatientQuoteReportList;
